<template>
  <div class="flex flex-col items-center">
    <div class="max-w-5xl lg:w-full w-5/6 lg:mx-0 mx-auto">

      <h2 class="text-2xl font-semibold py-4 border-b-2 border-red-600">
        Productivity & Financials
      </h2>
      <img
        src="../../assets/img/tools_productivity_financial_banner.jpg"
        alt="Legislation Banner"
        class="w-full lg:h-96 h-80 mt-4"
      >
      <br>
      <br>
      <div>
        Find out how is your company performing with respect to
        your competitors across different metrics.
      </div>
        <br>
        <img
          src="../../assets/img/tools_productivity_financial.png"
          alt="Expatriate Practice Banner"
          class="w-full mx-auto"
        >
        <br>
        <div class="mx-auto">
        <b>38,000 listed companies worldwide.</b> Select your target
        competitors and compare measures such as revenue or net
        profit or gross margin per employee, staff cost to revenue,
        etc.
      </div>
      <br>
      <br>
    </div>
  </div>
</template>

<style scoped>

</style>

<script>
export default {};
</script>
